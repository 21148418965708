import * as routePaths from "./RouteConstant";
import { RouteObject } from "react-router-dom";
import React, { Suspense, useContext } from "react";
import { AppContext } from "./App";
import { Layout } from "./shared/Layout";

const PageNotFound = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.PageNotFound,
  }))
);

const Dashboard = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.Dashboard,
  }))
);

const Profile = React.lazy(() => {
  return import("./pages").then((module) => ({
    default: module.Profile,
  }));
});

const LawyerAppointmentListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.LawyerAppointmentListPage,
  }))
);

const LawyerAppointmentViewPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.LawyerAppointmentViewPage,
  }))
);

const AppointmentSchedulePage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.AppointmentSchedulePage,
  }))
);

const HolidayCalendarPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.HolidayCalendarPage,
  }))
);

const BlogsListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.BlogsListPage,
  }))
);

const CasesListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.CasesListPage,
  }))
);

const CasesAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.CasesAddPage,
  }))
);

const CasesViewPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.CasesViewPage,
  }))
);

const DocumentResourceListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.DocumentResourceListPage,
  }))
);
const DocumentResourceAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.DocumentResourceAddPage,
  }))
);
const DocumentResourceEditPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.DocumentResouceEditPage,
  }))
);

const LeadsListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.LeadListPage,
  }))
);

const LeadViewPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.LeadViewPage,
  }))
);

const AuthRoutes = ({ component }: any) => {
  const { state } = useContext<any>(AppContext);
  const isLoading = state?.app_loading;

  if (isLoading) return null;

  return (
    <>
      {!isLoading ? (
        <Layout>
          <Suspense fallback={"Loading"}>{component}</Suspense>
        </Layout>
      ) : null}
    </>
  );
};

export const routes: RouteObject[] = [
  {
    path: "/",
    children: [
      {
        path: routePaths.DASHBOARD,
        element: <AuthRoutes component={<Dashboard />} />,
      },
      {
        path: routePaths.PROFILE,
        element: <AuthRoutes component={<Profile />} />,
      },
      {
        path: routePaths.LAWYER_APPOINTMENTS,
        element: <AuthRoutes component={<LawyerAppointmentListPage />} />,
      },
      {
        path: routePaths.LAWYER_VIEW_APPOINTMENT,
        element: <AuthRoutes component={<LawyerAppointmentViewPage />} />,
      },
      {
        path: routePaths.LAWYER_SCHEDULE,
        element: <AuthRoutes component={<AppointmentSchedulePage />} />,
      },
      {
        path: routePaths.LAWYER_HOLIDAYS,
        element: <AuthRoutes component={<HolidayCalendarPage />} />,
      },
      {
        path: routePaths.LAWYER_DOCUMENTS_ADD,
        element: <AuthRoutes component={<DocumentResourceAddPage />} />,
      },
      {
        path: routePaths.LAWYER_DOCUMENTS_EDIT,
        element: <AuthRoutes component={<DocumentResourceEditPage />} />,
      },
      {
        path: routePaths.LAWYER_DOCUMENTS_LIST,
        element: <AuthRoutes component={<DocumentResourceListPage />} />,
      },
      {
        path: routePaths.LAWYER_CASES_LIST,
        element: <AuthRoutes component={<CasesListPage />} />,
      },
      {
        path: routePaths.LAWYER_BLOGS,
        element: <AuthRoutes component={<BlogsListPage />} />,
      },
      {
        path: routePaths.LAWYER_CASES_ADD,
        element: <AuthRoutes component={<CasesAddPage />} />,
      },
      {
        path: routePaths.LAWYER_CASES_VIEW,
        element: <AuthRoutes component={<CasesViewPage />} />,
      },

      {
        path: routePaths.LAWYER_LEADS,
        element: <AuthRoutes component={<LeadsListPage />} />,
      },
      {
        path: routePaths.LAWYER_VIEW_LEAD,
        element: <AuthRoutes component={<LeadViewPage />} />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <Suspense fallback={"Loading"}>
        <PageNotFound />
      </Suspense>
    ),
  },
];
